import React, { Component } from 'react';
import styles from './ContentContainer.module.scss';

class ContentContainer extends Component {
  render() {
    return (
      <div className={styles.contentContainer}>
        {this.props.children}
      </div>
    );
  }
}

export default ContentContainer;
