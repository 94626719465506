import { Component } from 'react';
import { defineMessages } from 'react-intl';

// ============================================
// ================== Header ==================
// ============================================

export const header = defineMessages({
  headerLink1: {
    id: 'header.link1',
    defaultMessage: 'Why Us',
  },
  headerLink2: {
    id: 'header.link2',
    defaultMessage: 'Products',
  },
  headerLink3: {
    id: 'header.link3',
    defaultMessage: 'Contacts',
  },
});

// ===========================================
// ================== Intro ==================
// ===========================================

export const intro = defineMessages({
  introCaption: {
    id: 'intro.caption',
    defaultMessage: 'Visual Technology Solutions',
  },
  introDescription1: {
    id: 'intro.description1',
    defaultMessage: 'SMART',
  },
  introDescription2: {
    id: 'intro.description2',
    defaultMessage: 'INTUITIVE',
  },
  introDescription3: {
    id: 'intro.description3',
    defaultMessage: 'RELIABLE',
  },
  introButton: {
    id: 'intro.button',
    defaultMessage: 'Contact Us',
  },
  introScroll: {
    id: 'intro.scroll',
    defaultMessage: 'Scroll Down',
  },
});

// ============================================
// ================== Why Us ==================
// ============================================

export const whyUs = defineMessages({
  whyUsCaption: {
    id: 'why_us.caption',
    defaultMessage: 'Why Us?',
  },
  whyUsDescription: {
    id: 'why_us.description',
    defaultMessage: 'Few people understand visual technologies – in all its complexity – as well as we do. From a single component to the end user experience – we have rich practical expertise and deep knowledge to provide you best possible solution.',
  },
  whyUsItem1Caption: {
    id: 'why_us.item1.caption',
    defaultMessage: 'Team of business minded engineers',
  },
  whyUsItem1Description: {
    id: 'why_us.item1.description',
    defaultMessage: 'Each team member is a professional in a very specific field. Our business value is not a product itself, but the best possible solution for the exact client and his specific situation. We think about ROI of a customer and use our engineering minds to deliver best China market can offer.',
  },
  whyUsItem2Caption: {
    id: 'why_us.item2.caption',
    defaultMessage: 'Transparent cooperation',
  },
  whyUsItem2Description: {
    id: 'why_us.item2.description',
    defaultMessage: 'Working with us – is a simple and transparent experience. We are ready to openly introduce and explain choices of each component, solution or design applied in order to guarantee our customer total confidence in the product.',
  },
  whyUsItem3Caption: {
    id: 'why_us.item3.caption',
    defaultMessage: 'Tailor made projects',
  },
  whyUsItem3Description: {
    id: 'why_us.item3.description',
    defaultMessage: 'One of the main advantages is customizable solutions that we offer. Clients who have specific idea, demand for unique application or a request for product matching requirements of a tender – we are always ready to fully cooperate, advice and consult and eventually come up with positive result.',
  },
});

// ========================================================
// ================== Our Products ========================
// ========================================================

export const ourProducts = defineMessages({
  ourProductsCaption: {
    id: 'our_products.caption',
    defaultMessage: 'Our Products',
  },
  ourProductsItem1Caption: {
    id: 'our_products.item1.caption',
    defaultMessage: 'LCD products',
  },
  ourProductsItem2Caption: {
    id: 'our_products.item2.caption',
    defaultMessage: 'LED products',
  },
  ourProductsItem3Caption: {
    id: 'our_products.item3.caption',
    defaultMessage: 'Stage Light',
  },
  ourProductsItem4Caption: {
    id: 'our_products.item4.caption',
    defaultMessage: 'Other',
  },
  ourProductsButton: {
    id: 'our_products.button',
    defaultMessage: 'Download full catalog',
  },
  ourProductsSeeMore: {
    id: 'our_products.info',
    defaultMessage: 'See Most Popular',
  },
});

export const ourProductsLists = {
  // +++ Our Products - List 1 (opl1)
  ourProductsList1: defineMessages({
    opl1Item1: {
      id: 'our_products.list1.item1',
      defaultMessage: 'LCD Video Wall',
    },
    opl1Item2: {
      id: 'our_products.list1.item2',
      defaultMessage: 'Digital Signage',
    },
    opl1Item3: {
      id: 'our_products.list1.item3',
      defaultMessage: 'Interactive Flat Panel',
    },
    opl1Item4: {
      id: 'our_products.list1.item4',
      defaultMessage: 'CCTV Monitor',
    },
    opl1Item5: {
      id: 'our_products.list1.item5',
      defaultMessage: 'Commercial TV',
    },
    opl1Item6: {
      id: 'our_products.list1.item6',
      defaultMessage: 'Multi-touch Table',
    },
  }),
  // +++ Our Products - List 2 (opl2)
  ourProductsList2: defineMessages({
    opl2Item1: {
      id: 'our_products.list2.item1',
      defaultMessage: 'Rental LED Display',
    },
    opl2Item2: {
      id: 'our_products.list2.item2',
      defaultMessage: 'Fixed LED Display',
    },
    opl2Item3: {
      id: 'our_products.list2.item3',
      defaultMessage: 'Transparent display',
    },
    opl2Item4: {
      id: 'our_products.list2.item4',
      defaultMessage: 'Media facade',
    },
    opl2Item5: {
      id: 'our_products.list2.item5',
      defaultMessage: 'LED Screen for sport',
    },
    opl2Item6: {
      id: 'our_products.list2.item6',
      defaultMessage: 'LED modules',
    },
  }),
  // +++ Our Products - List 3 (opl3)
  ourProductsList3: defineMessages({
    opl3Item1: {
      id: 'our_products.list3.item1',
      defaultMessage: 'Galvo Scanner Lasers',
    },
    opl3Item2: {
      id: 'our_products.list3.item2',
      defaultMessage: 'Step-motor Lasers',
    },
    opl3Item3: {
      id: 'our_products.list3.item3',
      defaultMessage: 'Moving Head Lasers',
    },
    opl3Item4: {
      id: 'our_products.list3.item4',
      defaultMessage: 'Moving Head LED Light',
    },
    opl3Item5: {
      id: 'our_products.list3.item5',
      defaultMessage: 'Wall Washer Light',
    },
    opl3Item6: {
      id: 'our_products.list3.item6',
      defaultMessage: 'LED Par Light',
    },
  }),
  // +++ Our Products - List 4 (opl4)
  ourProductsList4: defineMessages({
    opl4Item1: {
      id: 'our_products.list4.item1',
      defaultMessage: '3D LED Hologram Fan',
    },
    opl4Item2: {
      id: 'our_products.list4.item2',
      defaultMessage: 'Rear Projection Film',
    },
    opl4Item3: {
      id: 'our_products.list4.item3',
      defaultMessage: 'Rotation Display Ball',
    },
    opl4Item4: {
      id: 'our_products.list4.item4',
      defaultMessage: 'Sensor Modular System',
    },
    opl4Item5: {
      id: 'our_products.list4.item5',
      defaultMessage: 'OPS Computer',
    },
    opl4Item6: {
      id: 'our_products.list4.item6',
      defaultMessage: 'Projectors',
    },
  })
}

export const fullCatalogFileName = "Arkadiko Technology Catalog.pdf";

// ===============================================================
// ================== Most Popular Products ==================
// ===============================================================

export const mostPopularProducts = defineMessages({
  popularProductsCaption: {
    id: 'popular_products.caption',
    defaultMessage: 'Most Popular Products',
  },
  popularProductsDownloadBtn: {
    id: 'popular_products.download_button',
    defaultMessage: 'Download Сatalog',
  },
});

// +++ Most Popular Products (mpp)
export const mostPopularProductsList = [
  // ===== Item 1 =====
  { catalogFile: "LCD VIDEO WALLS.pdf",
    images: {
      main: "flat-panel.jpg",
      feature1: "brightness.svg",
      feature2: "thin-bezel.svg",
      feature3: "angle.svg",
      feature4: "time.svg",
    },
    messages: defineMessages({
      mppItem1Caption: {
        id: 'popular_products.item1.caption',
        defaultMessage: 'LCD Video Walls',
      },
      mppItem1Description: {
        id: 'popular_products.item1.description',
        defaultMessage: 'Modular and scalable, ARKA video wall displays create huge screen surfaces with deep visual impact and thin bezel. Create visually stunning video walls for busy public areas, including shopping malls, dispatch centers, meeting rooms, conference rooms etc,that are easily installed, maintained and managed.',
      },
      mppItem1Feature1: {
        id: 'popular_products.item1.feature1',
        defaultMessage: 'Brightness 500-700 nits',
      },
      mppItem1Feature2: {
        id: 'popular_products.item1.feature2',
        defaultMessage: 'Thin bezel 0,88-3,5mm',
      },
      mppItem1Feature3: {
        id: 'popular_products.item1.feature3',
        defaultMessage: 'Wide viewing angle 178°(H)/178°(V)',
      },
      mppItem1Feature4: {
        id: 'popular_products.item1.feature4',
        defaultMessage: 'Designed for 24/7 operation',
      },
    }),
  },
  // ===== Item 2 =====
  { catalogFile: "DOUBLE-SIDED QLED DIGITAL SIGNAGE.pdf",
    images: {
      main: "signage.jpg",
      feature1: "rgb.svg",
      feature2: "repair.svg",
      feature3: "ultra-thin.svg",
      feature4: "hd.svg",
    },
    messages: defineMessages({
      mppItem2Caption: {
        id: 'popular_products.item2.caption',
        defaultMessage: 'Double-sided OLED Digital Signage',
      },
      mppItem2Description: {
        id: 'popular_products.item2.description',
        defaultMessage: 'This is an innovative option for commercial displays. This series consist from OLED panels which fixed between two layers of tempered glass, one at the front and one at the back. The surrounding transparent structure of display gives the impression that the signage is floating in the air and that the projection is coming directly from the glass pane.',
      },
      mppItem2Feature1: {
        id: 'popular_products.item2.feature1',
        defaultMessage: 'Better color output',
      },
      mppItem2Feature2: {
        id: 'popular_products.item2.feature2',
        defaultMessage: 'Variety of installation methods',
      },
      mppItem2Feature3: {
        id: 'popular_products.item2.feature3',
        defaultMessage: '2cm Ultra-thin design',
      },
      mppItem2Feature4: {
        id: 'popular_products.item2.feature4',
        defaultMessage: '4K Ultra HD picture',
      },
    }),
  },
  // ===== Item 3 =====
  { catalogFile: "INTERACTIVE FLAT PANEL.pdf",
    images: {
      main: "lcd.jpg",
      feature1: "multi-touch.svg",
      feature2: "hd.svg",
      feature3: "android.svg",
      feature4: "windows.svg",
    },
    messages: defineMessages({
      mppItem3Caption: {
        id: 'popular_products.item3.caption',
        defaultMessage: 'Interactive Flat Panel',
      },
      mppItem3Description: {
        id: 'popular_products.item3.description',
        defaultMessage: 'Iteractive Flat Panel (IFP) is an effective presentations tool. This multifunctional product brings lessons and idea sharing to life in education and corporate settings. Features include extensive connectivity, wireless content sharing and a ready-to-use whiteboard pack with pre-installed annotation tools.',
      },
      mppItem3Feature1: {
        id: 'popular_products.item3.feature1',
        defaultMessage: 'Multi-touch',
      },
      mppItem3Feature2: {
        id: 'popular_products.item3.feature2',
        defaultMessage: 'UHD resolution (3840x2160)',
      },
      mppItem3Feature3: {
        id: 'popular_products.item3.feature3',
        defaultMessage: 'Android system',
      },
      mppItem3Feature4: {
        id: 'popular_products.item3.feature4',
        defaultMessage: 'Optional built in OPS PC',
      },
    }),
  },
  // ===== Item 4 =====
  { catalogFile: "3D LED HOLOGRAM FAN.pdf",
    images: {
      main: "fan.jpg",
      feature1: "installation.svg",
      feature2: "eye.svg",
      feature3: "energy.svg",
      feature4: "funs.svg",
    },
    messages: defineMessages({
      mppItem4Caption: {
        id: 'popular_products.item4.caption',
        defaultMessage: '3D LED Hologram Fan',
      },
      mppItem4Description: {
        id: 'popular_products.item4.description',
        defaultMessage: '3D Hologram Fan is a display machine that uses the persistence of vision(POV) technology.   A belt composed of high-density LED lights spins at high speed, thus making the image/video vivid and tridimensional.',
      },
      mppItem4Feature1: {
        id: 'popular_products.item4.feature1',
        defaultMessage: 'Fast and convenient installation',
      },
      mppItem4Feature2: {
        id: 'popular_products.item4.feature2',
        defaultMessage: 'High attractiveness',
      },
      mppItem4Feature3: {
        id: 'popular_products.item4.feature3',
        defaultMessage: 'Low energy consumption',
      },
      mppItem4Feature4: {
        id: 'popular_products.item4.feature4',
        defaultMessage: 'Sync function(up to 36 fans)',
      },
    }),
  },
  // ===== Item 5 =====
  { catalogFile: "INFRARED MULTI-TOUCH (IRMT) FRAME.pdf",
    images: {
      main: "multitouch.jpg",
      feature1: "size.svg",
      feature2: "quick.svg",
      feature3: "multi-touch.svg",
      feature4: "puzzle.svg",
    },
    messages: defineMessages({
      mppItem5Caption: {
        id: 'popular_products.item5.caption',
        defaultMessage: 'Infrared Multi-touch (IRMT) frame',
      },
      mppItem5Description: {
        id: 'popular_products.item5.description',
        defaultMessage: 'Our IRMT frames offer a true multi-touch experience by transforming any screen or surface into an interactive display, whether it’s a new touch display installation, or retrofitting touch to an existing one. They are compatible with any screen or surface,such as LCD/LED screens, video walls or tables, Plasma or Projection displays and come as a retrofit kit which can be installed in minutes.',
      },
      mppItem5Feature1: {
        id: 'popular_products.item5.feature1',
        defaultMessage: 'Сustomized size (over 84”)',
      },
      mppItem5Feature2: {
        id: 'popular_products.item5.feature2',
        defaultMessage: 'Fast response (3-10ms)',
      },
      mppItem5Feature3: {
        id: 'popular_products.item5.feature3',
        defaultMessage: 'Support from 2 to 40 touch points',
      },
      mppItem5Feature4: {
        id: 'popular_products.item5.feature4',
        defaultMessage: 'Easy integration',
      },
    }),
  },
  // ===== Item 6 =====
  { catalogFile: "STAGE LASER LIGHTS.pdf",
    images: {
      main: "scenes.jpg",
      feature1: "eye.svg",
      feature2: "rgb.svg",
      feature3: "cable.svg",
      feature4: "pult.svg",
    },
    messages: defineMessages({
      mppItem6Caption: {
        id: 'popular_products.item6.caption',
        defaultMessage: 'Animation Laser ',
      },
      mppItem6Description: {
        id: 'popular_products.item6.description',
        defaultMessage: 'Animation Laser light is pre-programmed with 128/256 beam show, 96 kinds of geometric animations and 103 kinds of cartoon animations. You can make your own animated effects, graphics and texts by software. Faster scanner system, wider variety of colors! The ultrathin beam and special visible effects will decorate your scene in the best way and give you a fabulous visual feast.',
      },
      mppItem6Feature1: {
        id: 'popular_products.item6.feature1',
        defaultMessage: 'Cool stage effects: beams and animations',
      },
      mppItem6Feature2: {
        id: 'popular_products.item6.feature2',
        defaultMessage: 'Rich color options: single/RGB mixing',
      },
      mppItem6Feature3: {
        id: 'popular_products.item6.feature3',
        defaultMessage: 'Customizable power: 1- 20W or more',
      },
      mppItem6Feature4: {
        id: 'popular_products.item6.feature4',
        defaultMessage: 'Working modes: AUTO/Sound/DMX/M-S/PC',
      },
    }),
  },
  // ===== Item 7 =====
  { catalogFile: "STAGE LED LIGHTS.pdf",
    images: {
      main: "LED Light.jpg",
      feature1: "rgb.svg",
      feature2: "pult.svg",
      feature3: "eye.svg",
      feature4: "cable.svg",
    },
    messages: defineMessages({
      mppItem7Caption: {
        id: 'popular_products.item7.caption',
        defaultMessage: 'LED Par Light',
      },
      mppItem7Description: {
        id: 'popular_products.item7.description',
        defaultMessage: 'LED Par Cans are the most widely used lights for the stage, concerts, clubs, bars, DJ, disco, wedding, church,music live show, party etc. They come in a wide variety of sizes and shapes, from one pc to 72pcs of LEDs, slim to round type,different colors mixing, multiple effects like strobe or brightness change, low to high power,and indoor or outdoor use.Definitely, an essential decoration for your events to create the specific and beautiful atmosphere.',
      },
      mppItem7Feature1: {
        id: 'popular_products.item7.feature1',
        defaultMessage: 'Rich color option: Single RGB, UV, 3-IN-1 to 6-IN-1',
      },
      mppItem7Feature2: {
        id: 'popular_products.item7.feature2',
        defaultMessage: 'Control modes: Auto/Sound/M-S/DMX/Remote',
      },
      mppItem7Feature3: {
        id: 'popular_products.item7.feature3',
        defaultMessage: 'Cool Stage effects: Wall Wash, Strobe, Fade, Jump',
      },
      mppItem7Feature4: {
        id: 'popular_products.item7.feature4',
        defaultMessage: 'Customizable power: 0.5W-30W or more',
      },
    }),
  },
  // ===== Item 8 =====
  { catalogFile: "LED DANCE FLOOR.pdf",
    images: {
      main: "LED Dance Floor.jpg",
      feature1: "installation.svg",
      feature2: "pult.svg",
      feature3: "eye.svg",
      feature4: "size.svg",
    },
    messages: defineMessages({
      mppItem8Caption: {
        id: 'popular_products.item8.caption',
        defaultMessage: 'Wireless LED Dance Floor',
      },
      mppItem8Description: {
        id: 'popular_products.item8.description',
        defaultMessage: ' Wireless Magnetic Led Dance Floor is certainly a focus of party and events. The combination of Infinite 3D mirror & matte rainbow effects offer endless color possibilities to modify your surroundings, light up the atmosphere. Regular size per panel is 50x50cm (1.6x1.6ft) and 60x60cm (1.9x1.9ft ). With dmx control modes, the unit provides flexibility for controlling the color mixing and patterns creations for added creativity.',
      },
      mppItem8Feature1: {
        id: 'popular_products.item8.feature1',
        defaultMessage: 'Easy & quick to set up, No wire',
      },
      mppItem8Feature2: {
        id: 'popular_products.item8.feature2',
        defaultMessage: 'Programmable, create the effects as you want',
      },
      mppItem8Feature3: {
        id: 'popular_products.item8.feature3',
        defaultMessage: 'Multi effects: 3D mirror, Rainbow, Interactive marble, Twinkling 3D, Starlit etc',
      },
      mppItem8Feature4: {
        id: 'popular_products.item8.feature4',
        defaultMessage: 'Customizable size ',
      },
    }),
  },
];

// ============================================
// ================== Footer ==================
// ============================================

export const footer = defineMessages({
  footerСaption: {
    id: 'footer.caption',
    defaultMessage: 'Have questions? We have answers!',
  },
  footerDescription: {
    id: 'footer.description',
    defaultMessage: 'Fill in the form and our specialists will get in touch with you as soon as possible',
  },
  footerButton: {
    id: 'footer.button',
    defaultMessage: 'Contact Us',
  },
  footerCopyright: {
    id: 'footer.copyright',
    defaultMessage: '© Arkadiko. All right reserved',
  },
});

export const contacts = defineMessages({
  contactsCaption: {
    id: 'contacts.caption',
    defaultMessage: 'Contacts',
  },
  contactsDescription: {
    id: 'contacts.description',
    // Put {br} where you want to create break line
    defaultMessage: '3P01-C303, Block A, Building 1, Ronghui House, Country Garden, Wuhe Community, Bantian Street, Longgang District, Shenzhen City, Guangdong Province, China',
  },
});

export const contactLinks = {
  telephone: "+8618665975338",
  email: "info@arkadiko.tech",
}

// ========================================================
// ================== Contact Form Popup ==================
// ========================================================

export const form = defineMessages({
  formCaption: {
    id: 'form.caption',
    defaultMessage: 'Contact Form',
  },
  formDescription: {
    id: 'form.description',
    defaultMessage: 'Fill in  the form and our product expert will contact you',
  },
  formField1: {
    id: 'form.field1',
    defaultMessage: 'Name',
  },
  formField2: {
    id: 'form.field2',
    defaultMessage: 'E-mail',
  },
  formField3: {
    id: 'form.field3',
    defaultMessage: 'Your question',
  },
  formButton: {
    id: 'form.button',
    defaultMessage: 'Send',
  },
  formSuccessMessage: {
    id: 'form.success_message',
    defaultMessage: 'Your message has been sent :)',
  },
});

export default class Content extends Component {
  render() {
    return (null);
  }
};
