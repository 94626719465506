import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import styles from './Intro.module.scss';

import { scroll } from "utils/smoothScroll.js";
import { intro } from "content/en.js";

import { ReactComponent as LinkedinIcon } from './img/linkedin-icon.svg';
import { ReactComponent as FacebookIcon } from './img/fb-icon.svg';

class Intro extends Component {
  constructor(props) {
    super(props);

    this.intro = React.createRef();
    this.introTitle = React.createRef();
    this.introDesc = React.createRef();
    this.introButton = React.createRef();
    this.introBottom = React.createRef();
    this.introScrollDown = React.createRef();
    this.scrollDownText = React.createRef();
    this.scrollDownWheel = React.createRef();
    this.viewportOnTop = null;
  }

  componentDidMount() {
    this.viewportOnTop = document.documentElement.scrollTop === 0;
    this.initLoad = true;

    window.addEventListener('scroll', (e) => {
      const scrollTop = window.pageYOffset || e.target.scrollingElement.scrollTop;

      const intro = this.intro.current;
      const introTitle = this.introTitle.current;
      const introDesc = this.introDesc.current;
      const introButton = this.introButton.current;
      const introBottom = this.introBottom.current;
      const introScrollDown = this.introScrollDown.current;

      intro.style.backgroundPositionY = -scrollTop / ( document.documentElement.clientWidth > 768 ? 1.5 : 6) + "px";
      introTitle.style.opacity = 1 - (scrollTop / 90);
      introTitle.style.transform = "translate(" + scrollTop + "px, 0)";
      introDesc.style.opacity = 1 - (scrollTop / 200);
      introDesc.style.transform = "translate(-" + scrollTop + "px, 0)";
      introButton.style.opacity = 1 - (scrollTop / 300);
      introBottom.style.opacity = 1 - (scrollTop / 490);

      if (scrollTop <= 0 && this.viewportOnTop === false) {
        introScrollDown.style.opacity = "1";
        this.viewportOnTop = true;
      }

      if ((scrollTop > 0 && this.viewportOnTop === true) || (scrollTop > 0 && this.initLoad === true)) {
        introScrollDown.style.opacity = "0";
        this.viewportOnTop = false;
        this.initLoad = false;
      }
    });

    [this.scrollDownText, this.scrollDownWheel].forEach(item => {
      item.current.addEventListener('click', ev => {
        ev.preventDefault();

        scroll(document.getElementById("why-us"));
      });
    });
  }

  render() {
    const {
      setContactFormDisplayStatus
    } = this.props;

    return (
      <article className={styles.intro} ref={this.intro}>
        <h1 className={styles.title} ref={this.introTitle}>
          <span className={styles.anim}><FormattedMessage {...intro.introCaption} /></span>
        </h1>
        <div className={styles.desc} ref={this.introDesc}>
          <div className={cx(styles.descItem, styles.descItem1)}>
            <span><FormattedMessage {...intro.introDescription1} /></span>
          </div>
          <div className={cx(styles.descSeparator, styles.descSeparator1)}></div>
          <div className={cx(styles.descItem, styles.descItem2)}>
            <span><FormattedMessage {...intro.introDescription2} /></span>
          </div>
          <div className={cx(styles.descSeparator, styles.descSeparator2)}></div>
          <div className={cx(styles.descItem, styles.descItem3)}>
            <span><FormattedMessage {...intro.introDescription3} /></span>
          </div>
        </div>
        <div className={styles.btnWrap}>
          <button
            className={styles.btn}
            ref={this.introButton}
            onClick={() => setContactFormDisplayStatus("show")}
          >
            <FormattedMessage {...intro.introButton} />
          </button>
        </div>
        <div className={styles.bottom} ref={this.introBottom}>
          <div className={styles.blueLine}>
            <div className={styles.blueLineItem}></div>
          </div>
          <div className={styles.bottomContent}>
            <div className={styles.social}>
              <a className={styles.socialLink} href="https://linkedin.com/company/arkadiko-technology/about">
                <LinkedinIcon className={styles.socialIcon} />
              </a>
              <a className={styles.socialLink} href="https://www.facebook.com/arkadiko.tech">
                <FacebookIcon className={styles.socialIcon} />
              </a>
            </div>
            <div className={styles.scrollDownWrap}>
              <div className={styles.scrollDown} ref={this.introScrollDown}>
                <div className={styles.scrollDownIcon} ref={this.scrollDownWheel}>
                  <div className={styles.scrollDownWheel}></div>
                </div>
                <div className={styles.scrollDownText} ref={this.scrollDownText}>
                  <FormattedMessage {...intro.introScroll} />
                </div>
              </div>
            </div>
            <div className={styles.ridgepole}></div>
          </div>
          <div>{/* ridgepole */}</div>
        </div>
      </article>
    );
  }
}

export default Intro;
