import React, { Component } from 'react';
import styles from './OnTopButton.module.scss';

import { scroll } from "utils/smoothScroll.js";

import { ReactComponent as ArrowIcon } from './img/arrow.svg';

class OnTopButton extends Component {
  constructor(props) {
    super(props);

    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    this.buttonRef.current.addEventListener('click', (e) => {
      e.preventDefault();

      scroll(null, true);
    });

    let onTop = window.pageYOffset < document.documentElement.clientHeight;
    this.buttonRef.current.style.display = onTop ? "none" : "flex";

    window.addEventListener('scroll', () => {
      const clientHeight = document.documentElement.clientHeight;
      const pageYOffset = window.pageYOffset;
      const button = this.buttonRef.current;

      button.style.opacity = (pageYOffset - clientHeight) * 0.001;

      if (pageYOffset < clientHeight && !onTop) {
        button.style.display = "none";
        onTop = true;
      }

      if (pageYOffset >= clientHeight && onTop) {
        button.style.display = "flex";
        onTop = false;
      }
    });
  }

  render() {
    return (
      <div className={styles.buttonWrap}>
        <div className={styles.button} ref={this.buttonRef} hidden={true}>
          <ArrowIcon />
        </div>
      </div>
    );
  }
}

export default OnTopButton;
