import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
// import PropTypes from 'prop-types';

let messages = {
  en: {},
  ru: require('../content/ru.json'),
};

export default class LocalizationProvider extends Component {
  // static propTypes = {
  //   locale: PropTypes.string,
  // };

  static defaultProps = {
    locale: 'en',
  };

  render() {
    let { locale } = this.props;

    return (
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        {...this.props}
      />
    );
  }
}
