import React, { Component } from 'react';
import styles from './App.module.scss';

import { Header } from 'components/Header' ;
import { Intro } from 'components/Intro' ;
import { WhyUs } from 'components/WhyUs' ;
import { OurProducts } from 'components/OurProducts' ;
import { PopularProducts } from 'components/PopularProducts' ;
import { Footer } from 'components/Footer' ;
import { ContactUsForm } from 'components/ContactUsForm' ;
import { OnTopButton } from 'components/OnTopButton' ;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactFormStatus: "hide" // "hide", "fade", "show"
    };

    this.appRef = React.createRef()
  }

  pressEsc = (event) => {
    if (event.code === 'Escape') {
      this.setState(
        {contactFormStatus: "fade"},
        this.hideWithDelay
      );
    }
  }

  hideWithDelay = () => {
    setTimeout(() => {
      this.setState({contactFormStatus: "hide"})
    }, 650);
  }

  setContactFormDisplayStatus = (nextStatus) => {
    if (nextStatus === "hide") {
      document.removeEventListener('keydown', this.pressEsc);

      this.setState(
        {contactFormStatus: "fade"},
        this.hideWithDelay
      );
    } else {
      document.addEventListener('keydown', this.pressEsc);

      this.setState({contactFormStatus: nextStatus});
    }
  }

  render() {
    const {
      locale,
      changeLocale
    } = this.props;

    const {
      contactFormStatus
    } = this.state;

    return (
      <div className={styles.app} ref={this.appRef}>
        <Header locale={locale} changeLocale={changeLocale} />
        <Intro setContactFormDisplayStatus={this.setContactFormDisplayStatus} />
        <WhyUs />
        <OurProducts />
        <PopularProducts />
        <Footer setContactFormDisplayStatus={this.setContactFormDisplayStatus} />
        <ContactUsForm
          displayStatus={contactFormStatus}
          setDisplayStatus={this.setContactFormDisplayStatus}
        />
        <OnTopButton />
      </div>
    );
  }
}

export default App;
