import React, { Component } from 'react';
import styles from './Caption.module.scss';

class Caption extends Component {
  render() {
    return (
      <h1 className={styles.caption}>
        {this.props.children}
      </h1>
    );
  }
}

export default Caption;
