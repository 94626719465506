import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './WhyUs.module.scss';

import { Caption } from 'components/Caption';
import { ContentContainer } from 'components/ContentContainer';

import { whyUs } from "content/en.js";

import { ReactComponent as TeamIcon } from './img/team-icon.svg';
import { ReactComponent as CooperationIcon } from './img/cooperation-icon.svg';
import { ReactComponent as TailorIcon } from './img/tailor-icon.svg';

class WhyUs extends Component {
  constructor(props) {
    super(props);

    this.whyUsCaption = React.createRef();
    this.whyUsDesc = React.createRef();
    this.whyUsCategories = React.createRef();

    this.elements = [];
  }

  componentDidMount() {
    this.elements = [
      {element: this.whyUsCaption.current, display: false},
      {element: this.whyUsDesc.current, display: false},
      {element: this.whyUsCategories.current, display: false}
    ];

    window.addEventListener('scroll', (e) => {
      const scrollTop = window.pageYOffset || e.target.scrollingElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop === 0) {
        this.elements.forEach(currObj => {
          currObj.display = false;
          currObj.element.classList.remove(styles.show);
        });
      }

      this.elements.forEach(currObj => {
        const rangeToElem = currObj.element.getBoundingClientRect().top - Math.floor(clientHeight * 0.7);

        if (rangeToElem <= 0 && !currObj.display) {
          currObj.element.classList.add(styles.show);
          currObj.display = true;
        };
      });
    });
  }

  render() {
    return (
      <article className={styles.whyUs} id="why-us">
        <ContentContainer>
          <div className={styles.caption} ref={this.whyUsCaption}>
            <Caption><FormattedMessage {...whyUs.whyUsCaption} /></Caption>
          </div>
          <p className={styles.desc} ref={this.whyUsDesc}>
            <FormattedMessage {...whyUs.whyUsDescription} />
          </p>
          <div className={styles.categories} ref={this.whyUsCategories}>
            <section className={styles.categorie}>
              <div className={styles.categorieImageWrap}>
                <TeamIcon />
              </div>
              <h2 className={styles.categorieCaption}>
                <FormattedMessage {...whyUs.whyUsItem1Caption} />
              </h2>
              <p className={styles.categorieDesc}>
                <FormattedMessage {...whyUs.whyUsItem1Description} />
              </p>
            </section>
            <section className={styles.categorie}>
              <div className={styles.categorieImageWrap}>
                <CooperationIcon />
              </div>
              <h2 className={styles.categorieCaption}>
                <FormattedMessage {...whyUs.whyUsItem2Caption} />
              </h2>
              <p className={styles.categorieDesc}>
                <FormattedMessage {...whyUs.whyUsItem2Description} />
              </p>
            </section>
            <section className={styles.categorie}>
              <div className={styles.categorieImageWrap}>
                <TailorIcon />
              </div>
              <h2 className={styles.categorieCaption}>
                <FormattedMessage {...whyUs.whyUsItem3Caption} />
              </h2>
              <p className={styles.categorieDesc}>
                <FormattedMessage {...whyUs.whyUsItem3Description} />
              </p>
            </section>
          </div>
        </ContentContainer>
      </article>
    );
  }
}

export default WhyUs;
