import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './PopularProducts.module.scss';

import { Caption } from 'components/Caption';

import { mostPopularProducts, mostPopularProductsList } from 'content/en.js';

import { ReactComponent as DownloadIcon } from './img/download-icon.svg';

class PopularProducts extends Component {
  renderListOfProducts() {
    return mostPopularProductsList.map((currElem, index) => (
      <div className={styles.productWrap} key={index}>
        <div className={styles.blueLine}>
          <div className={styles.blueLineItem}></div>
        </div>
        <div>
          <h2 className={styles.productTitle}>
            <FormattedMessage {...currElem.messages[`mppItem${index + 1}Caption`]} />
          </h2>
          <div className={styles.contentWrap}>
            <div>
              <img
                className={styles.productImage}
                src={require(`content/img/${currElem.images.main}`)}
                alt=""
              />
            </div>
            <div>
              <p className={styles.productDesc}>
                <FormattedMessage {...currElem.messages[`mppItem${index + 1}Description`]} />
              </p>
              <div className={styles.featuresWrap}>
                <div className={styles.feature}>
                  <img
                    className={styles.featureImage}
                    src={require(`content/img/${currElem.images.feature1}`)}
                    alt=""
                  />
                  <p
                    className={styles.featureDesc}
                    style={{animationDelay: `${(index % 2 === 0) ? 0 : 5}s`}}
                  >
                    <FormattedMessage {...currElem.messages[`mppItem${index + 1}Feature1`]} />
                  </p>
                </div>
                <div className={styles.feature}>
                  <img
                    className={styles.featureImage}
                    src={require(`content/img/${currElem.images.feature2}`)}
                    alt=""
                  />
                  <p
                    className={styles.featureDesc}
                    style={{animationDelay: `${(index % 2 === 0) ? 1 : 6}s`}}
                  >
                    <FormattedMessage {...currElem.messages[`mppItem${index + 1}Feature2`]} />
                  </p>
                </div>
              </div>
              <div className={styles.featuresWrap}>
                <div className={styles.feature}>
                  <img
                    className={styles.featureImage}
                    src={require(`content/img/${currElem.images.feature3}`)}
                    alt=""
                  />
                  <p
                    className={styles.featureDesc}
                    style={{animationDelay: `${(index % 2 === 0) ? 2 : 7}s`}}
                  >
                    <FormattedMessage {...currElem.messages[`mppItem${index + 1}Feature3`]} />
                  </p>
                </div>
                <div className={styles.feature}>
                  <img
                    className={styles.featureImage}
                    src={require(`content/img/${currElem.images.feature4}`)}
                    alt=""
                  />
                  <p
                    className={styles.featureDesc}
                    style={{animationDelay: `${(index % 2 === 0) ? 3 : 8}s`}}
                  >
                    <FormattedMessage {...currElem.messages[`mppItem${index + 1}Feature4`]} />
                  </p>
                </div>
              </div>
              <div className={styles.downloadBtnWrap}>
                <a href={`${process.env.PUBLIC_URL}/${currElem.catalogFile}`}
                   className={styles.downloadBtn}
                   download=""
                >
                  <DownloadIcon />
                  <span><FormattedMessage {...mostPopularProducts.popularProductsDownloadBtn} /></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>{/* ridgepole */}</div>
      </div>
    ));
  }

  render() {
    return (
      <article className={styles.popularProducts}>
        <Caption><FormattedMessage {...mostPopularProducts.popularProductsCaption} /></Caption>
        {this.renderListOfProducts()}
      </article>
    );
  }
}

export default PopularProducts;
