import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import superagent from 'superagent';
import cx from 'classnames';
import styles from './ContactUsForm.module.scss';

import { form } from "content/en.js";

import { ReactComponent as ClosePopupBtn } from './img/close.svg';

class ContactUsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMessageSend: false,
      isCaptchaFieldMissed: false,
      disableForm: false,
      name: '',
      email: '',
      message: ''
    };

    this.messageInput = React.createRef();
  }

  setInputValue = (ev, field) => {
    this.setState({
      [field]: ev.target.value
    });
  }

  handleSubmit = (ev) => {
    ev.preventDefault();

    const recaptchaResponse = ev.target["g-recaptcha-response"] ? ev.target["g-recaptcha-response"].value : null;

    if (!recaptchaResponse) {
      this.setState(
        { isCaptchaFieldMissed: true },
        () => {
          setTimeout(() => {
            this.setState({ isCaptchaFieldMissed: false });
          }, 1100);
        }
      );
      return;
    } else {
      this.setState({ disableForm: true });
    }

    const {
      name,
      email,
      message
    } = this.state;

    superagent
      .post('/api/contact-form')
      .send({
        "name": name,
        "email": email,
        "note": message,
        "recaptchaResponse": recaptchaResponse
      })
      .then(res => {
        this.setState(
          { isMessageSend: true,
            disableForm: false
          },
          () => {
            this.messageInput.current.value = "";

            window.grecaptcha.reset();

            this.setState({message: ''});

            setTimeout(() => {
              this.setState({isMessageSend: false});
            }, 4000);
          }
        );
      })
      .catch(err => {
        this.setState({ disableForm: false });
        // err.message, err.response
      });
  }

  render() {
    const {
      displayStatus,
      setDisplayStatus
    } = this.props;

    const {
      name,
      email,
      message,
      isCaptchaFieldMissed,
      disableForm,
      isMessageSend
    } = this.state;

    return (
      <div
        className={cx(
          styles.containerWrap,
          { [styles.fade]: displayStatus === "fade",
            [styles.hide]: displayStatus === "hide"
          }
        )}
      >
        <div className={styles.container}>
          <div className={styles.popupWrap}>
            <div className={styles.popup}>
              <button className={styles.closePopupBtn} onClick={ev => setDisplayStatus("hide")}>
                <ClosePopupBtn />
              </button>
              <form
                onSubmit={this.handleSubmit}
                className={cx(
                  styles.form,
                  {[styles.disableForm]: disableForm}
                )}
              >
                <h2 className={styles.caption}>
                  <FormattedMessage {...form.formCaption} />
                </h2>
                <p className={styles.desc}>
                  <FormattedMessage {...form.formDescription} />
                </p>
                <div className={styles.field1}>
                  <label className={styles.label} htmlFor="nameField">
                    <FormattedMessage {...form.formField1} />
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="nameField"
                    onChange={ev => this.setInputValue(ev, "name")}
                    className={styles.input}
                    required
                  ></input>
                </div>
                <div className={styles.field2}>
                  <label className={styles.label} htmlFor="emailField">
                    <FormattedMessage {...form.formField2} />
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="emailField"
                    onChange={ev => this.setInputValue(ev, "email")}
                    className={styles.input}
                    required
                  ></input>
                </div>
                <div className={styles.field3}>
                  <label className={styles.label} htmlFor="messageField">
                    <FormattedMessage {...form.formField3} />
                  </label>
                  <textarea
                    name="message"
                    id="messageField"
                    ref={this.messageInput}
                    onChange={ev => this.setInputValue(ev, "message")}
                    className={styles.textarea}
                    required
                  ></textarea>
                </div>
                <div
                  className={cx(
                    "g-recaptcha",
                    styles.recaptcha,
                    { [styles.fieldMissed]: isCaptchaFieldMissed }
                  )}
                  data-sitekey="6LdeYrUUAAAAABH3i5-4WNpUIQAX3C84vfwN-8nx"
                ></div>
                <div className={styles.acceptBtnWrap}>
                  <button
                    className={cx(
                      styles.acceptBtn,
                      {[styles.active]: name && email && message}
                    )}
                    onSubmit={this.handleSubmit}
                  >
                    <span><FormattedMessage {...form.formButton} /></span>
                  </button>
                </div>
                { isMessageSend
                    ? <div className={styles.successMessage}>
                        <div>
                          <FormattedMessage {...form.formSuccessMessage} />
                        </div>
                      </div>
                    : null
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsForm;
