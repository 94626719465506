let nAgt = navigator.userAgent;
let browser = navigator.appName;

// MSIE
if (nAgt.indexOf('MSIE') !== -1) {
  browser = 'Microsoft Internet Explorer';
}
// Chrome
else if (nAgt.indexOf('Chrome') !== -1) {
  browser = 'Chrome';
}
// Safari
else if (nAgt.indexOf('Safari') !== -1) {
  browser = 'Safari';
}
// Firefox
else if (nAgt.indexOf('Firefox') !== -1) {
  browser = 'Firefox';
}
// MSIE 11+
else if (nAgt.indexOf('Trident/') !== -1) {
  browser = 'Microsoft Internet Explorer';
}

export const scroll = (element, toTop) => {
  const firstPos = window.pageYOffset || document.documentElement.scrollTop;

  let start = null,
      target = element ? (element.getBoundingClientRect().top - 70) : 0,
      pos = 0;

  if (target === 0 && !toTop) {
    return;
  }

  if (browser === "Chrome" || browser === "Firefox") {
    window.scrollTo({
      top: toTop ? 0 : (firstPos + target),
      behavior: "smooth"
    });

    return;
  }

  (() => {
    const browser = ['ms', 'moz', 'webkit', 'o'];

    for(let x = 0, length = browser.length; x < length && !window.requestAnimationFrame; x++) {
      window.requestAnimationFrame = window[browser[x]+'RequestAnimationFrame'];
      window.cancelAnimationFrame = window[browser[x]+'CancelAnimationFrame']
      || window[browser[x]+'CancelRequestAnimationFrame'];
    }
  })();

  const showAnimation = (timestamp) => {
    if (!start) { start = timestamp || new Date().getTime(); } //get id of animation

    const animationDuration = 800;
    const elapsed = timestamp - start;
    const progress = elapsed / animationDuration;

    //ease in function from https://github.com/component/ease/blob/master/index.js
    const outQuad = function(n) {
      return n * (2 - n);
    };

    const easeInPercentage = +(outQuad(progress)).toFixed(2);

    // if target is 0 (back to top), the position is: current pos + (current pos * percentage of duration)
    // if target > 0 (not back to top), the positon is current pos + (target pos * percentage of duration)
    pos = (target === 0)
          ? (firstPos - (firstPos * easeInPercentage))
          : (firstPos + (target * easeInPercentage));

    if (browser === "Microsoft Internet Explorer") {
      document.documentElement.scrollTop = pos;
    } else {
      window.scrollTo(0, pos);
    }

    if ((target > 0 && pos >= (firstPos + target)) ||
        (target < 0 && pos <= (firstPos + target)) ||
        (target === 0 && pos <= 0)
    ) {
      cancelAnimationFrame(start);

      pos = 0;
    } else {
      window.requestAnimationFrame(showAnimation);
    }
  }

  window.requestAnimationFrame(showAnimation);
}
