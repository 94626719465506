import React, { Component } from 'react';
import { render } from 'react-snapshot';

import './index.css';

import LocalizationProvider from './localization/LocalizationProvider.js';
import { App } from 'components/App';

class RootComponent extends Component {
  state = {
    locale: (window && window.localStorage) ? window.localStorage.getItem("lang") : "en"
  }

  changeLocale = (locale) => {
    window.localStorage.setItem("lang", locale);
    this.setState({locale: locale});
  }

  render() {
    const { locale } = this.state;

    return (
      <LocalizationProvider locale={locale}>
        <App changeLocale={this.changeLocale} locale={locale} />
      </LocalizationProvider>
    );
  }
}

render(
  <RootComponent />,
  document.getElementById('root')
);
