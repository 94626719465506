import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './OurProducts.module.scss';

import { Caption } from 'components/Caption';
import { ContentContainer } from 'components/ContentContainer';

import { ourProducts, ourProductsLists, fullCatalogFileName } from "content/en.js";

import { ReactComponent as DownloadIcon } from './img/download-icon.svg';
import { ReactComponent as ArrowIcon } from './img/arrow-icon.svg';

class OurProducts extends Component {
  constructor(props) {
    super(props);

    this.ourProducts = React.createRef();
    this.products = React.createRef();
    this.productsList1 = React.createRef();
    this.productsList2 = React.createRef();
    this.productsList3 = React.createRef();
    this.productsList4 = React.createRef();

    this.disableAnimation = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', (e) => {
      const scrollTop = window.pageYOffset || e.target.scrollingElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      const ourProducts = this.ourProducts.current;
      const products = this.products.current;
      const productsList1 = this.productsList1.current;
      const productsList2 = this.productsList2.current;
      const productsList3 = this.productsList3.current;
      const productsList4 = this.productsList4.current;

      const productsRangeMod = Math.round(
        (products.getBoundingClientRect().top - Math.floor(clientHeight * 0.3)) / 7
      );

      ourProducts.style.backgroundPositionY = productsRangeMod * -2 + "px";

      if (scrollTop === 0) {
        this.disableAnimation = false;
      }

      if (productsRangeMod <= 0 || this.disableAnimation) {
        if (!this.disableAnimation) {
          this.disableAnimation = true;
        }

        return;
      }

      if (window.innerWidth <= 900) {
        productsList1.style.transform = `translate(-${productsRangeMod}px, ${productsRangeMod}px)`;
        productsList2.style.transform = `translate(${productsRangeMod}px, ${productsRangeMod}px)`;
        productsList3.style.transform = `translate(-${productsRangeMod * 2}px, ${productsRangeMod / 2}px)`;
        productsList4.style.transform = `translate(${productsRangeMod * 2}px, ${productsRangeMod / 2}px)`;
      } else {
        productsList1.style.transform = `translate(-${productsRangeMod * 2}px, ${productsRangeMod / 2}px)`;
        productsList2.style.transform = `translate(-${productsRangeMod}px, ${productsRangeMod}px)`;
        productsList3.style.transform = `translate(${productsRangeMod}px, ${productsRangeMod}px)`;
        productsList4.style.transform = `translate(${productsRangeMod * 2}px, ${productsRangeMod / 2}px)`;
      }
    });
  }

  getCategorieItems(list) {
    return Object.keys(ourProductsLists[list]).map(key => (
      <span key={key}>
        <FormattedMessage {...ourProductsLists[list][key]} />
      </span>
    ))
  }

  render() {
    return (
      <article className={styles.ourProducts} id="products" ref={this.ourProducts}>
        <ContentContainer>
          <Caption>
            <FormattedMessage {...ourProducts.ourProductsCaption} />
          </Caption>
          <div className={styles.categories} ref={this.products}>
            <section className={styles.categorie} ref={this.productsList1}>
              <h2 className={styles.categorieCaption}>
                <FormattedMessage {...ourProducts.ourProductsItem1Caption} />
              </h2>
              <div className={styles.categorieDesc}>
                {this.getCategorieItems("ourProductsList1")}
            </div>
            </section>
            <section className={styles.categorie} ref={this.productsList2}>
              <h2 className={styles.categorieCaption}>
                <FormattedMessage {...ourProducts.ourProductsItem2Caption} />
              </h2>
              <div className={styles.categorieDesc}>
                {this.getCategorieItems("ourProductsList2")}
              </div>
            </section>
            <section className={styles.categorie} ref={this.productsList3}>
              <h2 className={styles.categorieCaption}>
                <FormattedMessage {...ourProducts.ourProductsItem3Caption} />
              </h2>
              <div className={styles.categorieDesc}>
                {this.getCategorieItems("ourProductsList3")}
              </div>
            </section>
            <section className={styles.categorie} ref={this.productsList4}>
              <h2 className={styles.categorieCaption}>
                <FormattedMessage {...ourProducts.ourProductsItem4Caption} />
              </h2>
              <div className={styles.categorieDesc}>
                {this.getCategorieItems("ourProductsList4")}
              </div>
            </section>
          </div>
          <a href={`${process.env.PUBLIC_URL}/${fullCatalogFileName}`}
             className={styles.downloadFullBtn}
             download=""
          >
            <DownloadIcon />
            <span><FormattedMessage {...ourProducts.ourProductsButton} /></span>
          </a>
          <div className={styles.seeMore}>
            <div>
              <FormattedMessage {...ourProducts.ourProductsSeeMore} />
            </div>
            <ArrowIcon />
          </div>
        </ContentContainer>
      </article>
    );
  }
}

export default OurProducts;
