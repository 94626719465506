import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Footer.module.scss';

import { Caption } from 'components/Caption';

import { footer, contacts, contactLinks } from "content/en.js";

import { ReactComponent as LinkedinIcon } from './img/linkedin-icon.svg';
import { ReactComponent as FacebookIcon } from './img/fb-icon.svg';
import { ReactComponent as PhoneIcon } from './img/phone.svg';
import { ReactComponent as LetterIcon } from './img/letter.svg';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.footer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', (e) => {
      const footer = this.footer.current;
      const footerRangeMod = Math.round(footer.getBoundingClientRect().top / -3.5);

      footer.style.backgroundPositionY = footerRangeMod + "px";
    });
  }

  getCurrYear = () => {
    const currYear = new Date().getFullYear();

    return currYear;
  }

  render() {
    const {
      setContactFormDisplayStatus
    } = this.props;

    return (
      <footer className={styles.footer} id="contacts" ref={this.footer}>
        <Caption>
          <FormattedMessage {...footer.footerСaption} />
        </Caption>
        <div className={styles.desc}>
          <FormattedMessage {...footer.footerDescription} />
        </div>
        <button className={styles.btn} onClick={() => setContactFormDisplayStatus("show")}>
          <FormattedMessage {...footer.footerButton} />
        </button>
        <div className={styles.contactsWrap}>
          <div className={styles.blueLine1}>
            <div className={styles.blueLineItem}></div>
          </div>
          <div className={styles.contactsCaption}>
            <FormattedMessage {...contacts.contactsCaption} />
          </div>
          <div>{/* ridgepole */}</div>
          <div>{/* ridgepole */}</div>
          <div className={styles.contacts}>
            <div className={styles.contactsDesc}>
              <FormattedMessage {...contacts.contactsDescription} values={{br: <br/>}} />
            </div>
            <div className={styles.contactLinks}>
              <a className={styles.contactLink} href={`tel:${contactLinks.phone}`}>
                <PhoneIcon />
                {contactLinks.telephone}
              </a>
              <a className={styles.contactLink} href={`mailto:${contactLinks.email}`}>
                <LetterIcon />
                {contactLinks.email}
              </a>
            </div>
            <div className={styles.social}>
              <a className={styles.socialLink} href="https://linkedin.com/company/arkadiko-technology/about">
                <LinkedinIcon className={styles.socialIcon} />
              </a>
              <a className={styles.socialLink} href="https://www.facebook.com/arkadiko.tech">
                <FacebookIcon className={styles.socialIcon} />
              </a>
            </div>
          </div>
          <div className={styles.blueLine2}>
            <div className={styles.blueLineItem}></div>
          </div>
        </div>
        <div className={styles.copyright}>
          <FormattedMessage {...footer.footerCopyright} />, 2018-{this.getCurrYear()}
        </div>
      </footer>
    );
  }
}

export default Footer;
