import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import styles from './Header.module.scss';

import { header } from "content/en.js";
import { scroll } from "utils/smoothScroll.js";

import { ReactComponent as Logo } from './img/logo.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.header = React.createRef();

    this.whyUsRef = React.createRef();
    this.productsRef = React.createRef();
    this.contactsRef = React.createRef();

    this.state = { displayMobileMenu: false };
  }

  componentDidMount() {
    window.addEventListener('scroll', (e) => {
      const scrollTop = window.pageYOffset || e.target.scrollingElement.scrollTop;
      const header = this.header.current;

      header.style.backgroundPositionY = -scrollTop / 1.5 + "px";
    });

    [this.whyUsRef, this.productsRef, this.contactsRef].forEach(item => {
      const target = item.current.getAttribute('dataid');

      item.current.addEventListener('click', ev => {
        ev.preventDefault();

        if (this.state.displayMobileMenu) {
          this.setState(
            {displayMobileMenu: false},
            () => scroll(document.getElementById(target))
          );
        } else {
          scroll(document.getElementById(target));
        }
      });
    });
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      displayMobileMenu: !prevState.displayMobileMenu
    }));
  }

  render() {
    const {
        locale,
        changeLocale
      } = this.props;

    const {
      displayMobileMenu
    } = this.state;

    return (
      <header className={styles.header} ref={this.header}>
        <div>{/* ridgepole */}</div>
        <div className={styles.content}>
          <a className={styles.logo} href="/" onClick={ev => ev.preventDefault()}>
            <Logo />
          </a>
          <div className={cx(
            styles.rightSide,
            {[styles.mobile]: displayMobileMenu}
          )}>
            <nav className={styles.nav}>
              <ul className={styles.menu}>
                <li className={styles.menuItem} dataid="why-us" role="button" ref={this.whyUsRef}>
                  <FormattedMessage {...header.headerLink1} />
                </li>
                <li className={styles.menuItem} dataid="products" role="button" ref={this.productsRef}>
                  <FormattedMessage {...header.headerLink2} />
                </li>
                <li className={styles.menuItem} dataid="contacts" role="button" ref={this.contactsRef}>
                  <FormattedMessage {...header.headerLink3} />
                </li>
              </ul>
            </nav>
            <div className={styles.lang}>
              <button
                className={cx(
                  styles.langBtn,
                  {[styles.langBtnActive]: locale !== "ru"}
                )}
                onClick={() => locale !== "en" && changeLocale("en")}
              >En</button>
              <button
                className={cx(
                  styles.langBtn,
                  {[styles.langBtnActive]: locale === "ru"}
                )}
                onClick={() => locale !== "ru" && changeLocale("ru")}
              >Ru</button>
            </div>
          </div>
          <div
            className={cx(
              styles.mobileMenuBtn,
              {[styles.mobileMenuBtnActive]: displayMobileMenu}
            )}
            onClick={() => this.toggleMobileMenu()}
          >
            <div className={styles.mobileMenuBtnItem}></div>
            <div className={styles.mobileMenuBtnItem}></div>
            <div className={styles.mobileMenuBtnItem}></div>
            <div className={styles.mobileMenuBtnConvas}></div>
          </div>
        </div>
        <div className={styles.blueLine}>
          <div className={styles.blueLineItem}></div>
        </div>
      </header>
    );
  }
}

export default Header;
